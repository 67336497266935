// import styles from './Tooltip.module.css'

type Props = {
  tooltip: ReactNode,
  children: ReactNode,
  alignment?: 'top' | 'bottom',
  className?: string,
  color?: string,
}

import { ReactNode } from 'react'
export const Tooltip = ({
  tooltip,
  children,
  alignment = 'top',
  className,
  color = 'bg-emerald-600'
}: Props) => {
  return (
    <div className={`relative flex flex-col lg:items-center group ${ alignment === 'top' ? 'item-end' : 'items-start' }`}>
      {children}
      <div className={`absolute flex-col items-center mb-6 hidden group-hover:flex z-50 ${
        alignment === 'top'
          ? 'bottom-0'
          : 'top-[140%]'
      } ${ className }`}>
        <span className={`relative z-10 p-4 text-sm text-white shadow-lg rounded-md ${ color }`}>{tooltip}</span>
        { alignment === 'top'
          ? <div className={`w-3 h-3 -mt-2 -ml-16 lg:ml-0 rotate-45 ${ color }`}></div>
          : <div className={`w-3 h-3 -ml-16 lg:ml-0 -rotate-45 absolute -top-1 ${ color }`}></div>
        }
      </div>
    </div>
  )
}

export default Tooltip
