import styles from './HowItWorksDiagram.module.css'
import {
  AiOutlineFunnelPlot,
  AiFillTwitterCircle,
  AiOutlineFileText,
  AiFillLinkedin,
} from 'react-icons/ai'
import {
  ImMagicWand,
} from 'react-icons/im'
import {
  BsLightningFill, BsLinkedin, BsMedium,
} from 'react-icons/bs'
import {
  TiArrowDownThick
} from 'react-icons/ti'
import NumberIcon from 'components/NumberIcon'
// import { CalendarIcon } from '@heroicons/react/outline'
import TwitterIcon from 'components/Icons/Twitter'
import { range } from 'lodash'
import Tooltip from 'components/Tooltip'

type Props = {
  iconOnly?: boolean,
  isSmall?: boolean,
  showAllSteps?: boolean,
}

const HowItWorksDiagram = ({ iconOnly = false, isSmall = false, showAllSteps = false }: Props) =>
  <div className={styles.howItWorksDiagram}>
    <div className={`font-[Montserrat] mx-auto flex flex-col gap-4  relative ${ iconOnly ? '' : 'sm:-left-40'}`}>
      <div className={`flex flex-wrap justify-center gap-8 text-indigo-500 relative ${ iconOnly ? '' : 'sm:w-[480px]'} mx-auto`}>
        { !iconOnly && <div className='flex flex-shrink-0 justify-center sm:justify-start items-center flex-col sm:flex-row sm:whitespace-nowrap flex-nowrap flex-[100%] sm:flex-auto sm:absolute sm:left-[380px] sm:top-[22%] uppercase font-bold text-center sm:text-left sm:w-[480px] mx-auto'>
          <NumberIcon className='!border-indigo-500 !text-indigo-500 !mr-3 mb-2 sm:mb-0'>1</NumberIcon>
          <div className='sm:inline-block'>
            <span className='text-slate-500'>You give Blotter </span>
            <span>Your Blog’s URL</span>
            <div className='text-xs text-slate-500 normal-case font-normal max-w-xs whitespace-normal mt-1'>Blotter catalogs your blog posts and determines Tweets/post automatically.</div>
          </div>
        </div> }
        <Tooltip tooltip='Your blog post' color='bg-slate-400' className='w-48 bottom-1/3'>
          <AiOutlineFileText size={64} className='transform -rotate-12 relative top-4 left-3' />
        </Tooltip>
        <Tooltip tooltip='Your blog post' color='bg-slate-400' className='w-48 bottom-1/3'>
          <AiOutlineFileText size={80} className='transform rotate-2' />
        </Tooltip>
        <Tooltip tooltip='Your blog post' color='bg-slate-400' className='w-48 bottom-1/3'>
          <AiOutlineFileText size={58} className='transform rotate-6 relative right-4 top-5' />
        </Tooltip>
      </div>

      <div className={`flex justify-center relative sm:my-0 ${ isSmall ? 'sm:top-1 mt-1 mb-2' : '-top-4 sm:top-3 my-4' }`}>
        <TiArrowDownThick size={24} className='text-slate-300' />
      </div>

      <div className={`flex flex-wrap justify-center text-slate-500 relative -mt-8 ${ isSmall ? 'sm:-mt-4' : 'sm:mt-0 sm:w-[480px]' } mx-auto`}>
        { !iconOnly && <div className='flex flex-shrink-0 justify-center sm:justify-start !items-center flex-col sm:flex-row  flex-nowrap flex-[100%] sm:flex-auto sm:absolute text-center sm:text-left left-[380px] top-[36%] uppercase font-bold sm:w-[480px] mx-auto'>
          <NumberIcon className='!border-emerald-500 !text-emerald-500 sm:!mr-3 inline-block mb-2 sm:mb-0'>2</NumberIcon>
          <div className='sm:inline-block'>
            Blotter works <span className='text-emerald-500'>THE MAGIC</span>
            <div className='text-xs text-slate-500 normal-case font-normal max-w-xs whitespace-normal mt-1'>Blotter uses both algorithms and human skill to craft Twitter threads and Tweets based on your blog posts.</div>
          </div>
        </div> }
        <Tooltip tooltip='Blotter’s Automagical Content Repurposing System' color='bg-slate-400' className='bottom-2/3'>
          <AiOutlineFunnelPlot size={200} />
          <div className={`absolute inset-0 flex justify-center items-center ${ iconOnly ? '-mt-16' : 'mt-4'} sm:mt-0`}>
            <div className='relative top-8 sm:top-0'>
              <ImMagicWand size={100} color='#111' className='rounded-full bg-white border-slate-500 border-8 p-4' />
              <div className='absolute -left-10 top-4 text-yellow-400 -rotate-[60deg]'>
                <BsLightningFill size={40} />
              </div>
              <div className='absolute -right-10 top-4 text-yellow-400 -scale-x-100 rotate-[60deg]'>
                <BsLightningFill size={40} />
              </div>
              <div className='absolute -left-10 bottom-4 text-yellow-400 -rotate-[90deg]'>
                <BsLightningFill size={40} />
              </div>
              <div className='absolute -right-10 bottom-4 text-yellow-400 -scale-x-100 rotate-[90deg]'>
                <BsLightningFill size={40} />
              </div>
            </div>
          </div>
        </Tooltip>
      </div>

      <div className={`flex justify-center relative sm:mb-0 ${ isSmall ? '-mt-5 mb-1 sm:-mt-4 sm:-top-1' : '-mt-4 sm:mt-2 mb-2 sm:-top-5' }`}>
        <TiArrowDownThick size={24} className='text-slate-300' />
      </div>

      <div className={`flex flex-wrap justify-center gap-6 text-sky-400 items-center relative ${ iconOnly ? '' : 'sm:w-[480px]'} mx-auto`}>
        { !iconOnly && <div className='flex flex-shrink-0 justify-center sm:justify-start items-center flex-col sm:flex-row flex-nowrap flex-[100%] sm:flex-auto sm:absolute  sm:left-[380px] sm:-top-[12%] sm:w-[480px] uppercase font-bold gap-x-3'>
          <NumberIcon className='!border-sky-500 !text-sky-500 mb-2 sm:mb-0'>3</NumberIcon>
          <div className='text-center sm:text-left w-full sm:w-auto flex flex-col items-center sm:block'>
            <div className='w-64'>
              <span className='text-slate-500'>You get </span>
              <span>quality Tweets and Threads </span>
              <span className='text-slate-500'>in </span>
              <span><em>your</em> style</span>
            </div>
            <div className='w-80 text-xs text-slate-500 normal-case font-normal whitespace-normal mt-1'>Unlike AI Tweet generators, Blotter crafts Tweets and Threads that reflect your own style and voice. And they’re yours to do with as you please.</div>
          </div>
        </div> }
        <Tooltip tooltip='LinkedIn' color='bg-slate-400'>
          <AiFillLinkedin size={40} className='rotate-12 text-[#1569BF]' />
        </Tooltip>
        <Tooltip tooltip='Twitter' color='bg-slate-400'>
          <AiFillTwitterCircle size={34} className='-rotate-12' />
        </Tooltip>
        <Tooltip tooltip='Twitter' color='bg-slate-400'>
          <AiFillTwitterCircle size={42} className='rotate-3 relative -top-1' />
        </Tooltip>
      </div>

      <div className='flex justify-center gap-6 text-sky-400 items-center relative'>
        <Tooltip tooltip='Twitter' color='bg-slate-400'>
          <AiFillTwitterCircle size={48}  />
        </Tooltip>
        <Tooltip tooltip='Medium' color='bg-slate-400'>
          <BsMedium size={48} className='text-black relative left-1' />
        </Tooltip>
      </div>

      { showAllSteps && <div className={`flex flex-col text-rose-400 items-center justify-between mt-8 relative mx-auto ${ iconOnly ? '' : 'sm:w-[480px]' }`}>
        <div className={`flex justify-center relative sm:mb-5 ${ isSmall ? '-mt-5 mb-1 sm:-mt-4 sm:-top-1' : '-mt-4 sm:mt-2 mb-3 sm:mb-2 sm:-top-5' }`}>
          <TiArrowDownThick size={24} className='text-slate-300' />
        </div>
        { !iconOnly && <div className='mt-4 flex flex-shrink-0 justify-center sm:justify-start items-center flex-col sm:flex-row flex-nowrap flex-[100%] sm:flex-auto sm:absolute sm:left-[380px] sm:top-[28%] sm:w-[480px] uppercase font-bold gap-x-3'>
          <NumberIcon className='!border-rose-400 !text-rose-400 mb-2 sm:mb-0'>4</NumberIcon>
          <div className='text-center sm:text-left w-full sm:w-auto flex flex-col items-center sm:block'>
            <div className='w-64'>
              <span className='text-slate-500'>Everything is organized into a </span>
              <span>Content Schedule</span>
            </div>
            <div className='sm:text-left w-[22rem]'>
              <div className='text-xs text-slate-500 normal-case font-normal max-w-[22rem] whitespace-normal mt-1'>Blotter intersperses Tweets from different blog posts to create your personalized Content Schedule (soon with Medium and LinkedIn dates too).</div>
            </div>
          </div>
        </div> }
        <Tooltip tooltip='Your Content Schedule' color='bg-slate-400' className='bottom-2/3'>
          <div className='w-32 mt-8 sm:mt-0'>
            <div className='bg-rose-400 rounded-t-lg h-6' />
            <div className='grid grid-cols-4 gap-y-1 border-2 border-rose-400 pl-[6px] pr-0 pt-2 pb-3 rounded-b-lg'>
              { range(11).map((index: number) => <div key={index} className='first:opacity-0'>
                { index === 4 && <BsLinkedin fill='white' className='bg-[#1569BF] w-6 h-6 rounded p-[3px]' />}
                { index === 9 && <BsMedium fill='black' className='border-slate-300 border w-6 h-6 rounded p-[3px]' />}
                { ![4, 9].includes(index) && <TwitterIcon fill='white' width={24} height={24} className='bg-sky-400 rounded p-[3px]' /> }
                {/* <div className='bg-red-400 rounded'>
                  <AiFillTwitterCircle size={28} className='fill-sky-400 text-white' />
                </div> */}
              </div> )}
            </div>
          </div>
        </Tooltip>
      </div> }
    </div>
  </div>

export default HowItWorksDiagram
