import classNames from 'classnames'
import BlotterIcon from 'components/Icons/BlotterIcon3'
import styles from './BlotterLogo.module.css'

type Props = {
  name?: string,
  size?: 'small' | 'default',
}

const sizes = {
  default: {
    icon: 48,
    text: 'text-3xl',
    textHeight: 'h-[28px]',
  },
  small: {
    icon: 32,
    text: 'text-2xl',
    textHeight: 'h-[24px]',
  },
}

const BlotterLogo = ({ name = 'logo', size = 'default' }: Props) => {
  const sizeInfo = sizes[size]
  return <div className={styles.blotterLogo}>
    <div className={styles.icon}>
      <BlotterIcon name={name} width={sizeInfo.icon} height={sizeInfo.icon} />
    </div>
    <div className={classNames(styles.text, sizeInfo.text, sizeInfo.textHeight)}>Blotter</div>
  </div>
}

export default BlotterLogo
