import classNames from 'classnames'
import styles from './NumberIcon.module.css'

type Props = {
  children: string,
  className?: string,
}

const NumberIcon = ({ children, className = '' }: Props) =>
  <div className={classNames(styles.numberIcon, className)}>{ children }</div>

export default NumberIcon
